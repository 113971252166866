import * as React from "react";
import logo from "./img/logo.jpg";
import { AppBar, Box, CssBaseline, Toolbar, Typography } from "@mui/material";

function DrawerAppBar(props) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="sticky"
        sx={{ backgroundColor: "black" }}
      >
        <Toolbar>
          <Box
            component="img"
            sx={{
              height: 40,
              marginRight: 2,
            }}
            alt="Logo"
            src={logo}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Sylberize
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default DrawerAppBar;
