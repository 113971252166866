import { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  Avatar,
  CardContent,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Header from "./Header";
import * as yup from "yup";
import { Formik } from "formik";
import emailjs from "@emailjs/browser";
import Image from "./img/aboutUsBackground.jpg";
import Video from "./img/Sylberize_Video.mp4";

const schema = yup.object().shape({
  name: yup.string().trim().required("A name is required."),
  email: yup
    .string()
    .email("A valid email address is required.")
    .required("A email is required."),
  message: yup.string().trim().required("A message is required."),
});

const teamMembers = [
  {
    name: "Alexander Enzinger",
    role: "Project Sponsor",
    image: "/imgs/aboutUs/alexander-enzinger.jpg",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    name: "Colton Weatherston",
    role: "Back End Developer",
    image: "/imgs/aboutUs/colton-weatherston.jpg",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    name: "Reacher Dang",
    role: "Project Lead & Front End Developer",
    image: "/imgs/aboutUs/reacher-dang.jpg",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

const ContactForm = () => {
  const [isSending, setIsSending] = useState(false);
  const [alertDisabled, setAlertDisabled] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleFormData = async (values, { resetForm, setSubmitting }) => {
    setIsSending(true);
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_CONTACT_TEMPLATE_ID,
        values,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY,
      )
      .then(
        (result) => {
          setIsSending(false);
          setAlertSeverity("success");
          setAlertMessage("Message sent!");
          setAlertDisabled(false);
          resetForm();
          setSubmitting(false);
          setTimeout(() => {
            setAlertDisabled(true);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setIsSending(false);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong, please try again later.");
          setAlertDisabled(false);
          setSubmitting(false);
          console.log(error);
          setTimeout(() => {
            setAlertDisabled(true);
          }, 5000); // hide message after 5 seconds
        },
      );
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleFormData}
      initialValues={{
        name: "",
        email: "",
        message: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label="Name"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="message"
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                error={touched.message && errors.message}
                helperText={errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                color="primary"
                loading={isSending}
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="contained"
                fullWidth
              >
                Send
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Alert
                variant="filled"
                severity={alertSeverity}
                disabled={alertDisabled}
              >
                {alertMessage}
              </Alert>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

function App() {
  return (
    <div className="App">
      <Header />
      <Box
        sx={{
          backgroundImage: `url(${Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          py: 4,
          height: "80vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  p: 4,
                  borderRadius: 2,
                  color: "white",
                }}
              >
                <Typography
                  variant="h3"
                  component="h1"
                  gutterBottom
                  align="left"
                >
                  Our Vision
                </Typography>
                <Typography variant="body1" paragraph align="left">
                  Welcome to our platform, dedicated to empowering local service
                  providers. We offer a space for small business owners to
                  showcase their work and connect with local customers
                  effectively. Our booking system simplifies schedule management
                  and includes online billing to protect the interests of both
                  customers and businesses. Our goal is to provide a
                  user-friendly experience that helps providers and customers
                  engage seamlessly.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <video width="100%" controls>
                  <source src={Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mt: 0, mb: 6 }}
          >
            Our Team
          </Typography>
          <Grid container>
            {teamMembers.map((member, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                direction={index % 2 === 0 ? "row" : "row-reverse"}
                alignItems="center"
                sx={{
                  background:
                    index % 2 === 0
                      ? "linear-gradient(to right, #e0e0e0, #ffffff)"
                      : "linear-gradient(to right, #ffffff, #e0e0e0)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  py: 4,
                  px: 2,
                  borderRadius: 3,
                  mb: 4,
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ display: "flex", justifyContent: "center", zIndex: 2 }}
                >
                  <Avatar
                    alt={member.name}
                    src={member.image}
                    sx={{
                      width: 180,
                      height: 180,
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={9} sx={{ zIndex: 2 }}>
                  <Box sx={{ p: 2 }}>
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontWeight: "bold", color: "#333" }}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontStyle: "italic", color: "#555" }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        variant="body2"
                        paragraph
                        sx={{ fontSize: "1.1rem", color: "#666" }}
                      >
                        {member.description}
                      </Typography>
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Box sx={{ width: "100%", backgroundColor: "#f5f5f5", py: 4 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mt: 0 }}
          >
            Contact Us
          </Typography>
          <Box sx={{ mt: 4 }}>
            <ContactForm />
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default App;
